import noop from 'lodash/noop';
import { type ReactNode, useMemo, useRef } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';

type BorderStyle = 'white' | 'gray' | 'none';

function useBorderClasses(borderStyle?: BorderStyle) {
  return useMemo(() => {
    switch (borderStyle) {
      case 'white':
        return 'border border-white';
      case 'gray':
        return 'border border-[#303436]';
      case 'none':
        return '';
      default:
        return 'border-2 border-transparent border-solid box-border bg-clip-padding before:gradientBorder';
    }
  }, [borderStyle]);
}

// NOTE(falcon): this is small wrapper to clone the modal styles.
// ideally, we should encapsulate some of this style in the modal provider.
// for now, to avoid a bigger change, just duplicating this styling here.
export function ModalWrapper(props: {
  children: ReactNode;
  borderStyle?: BorderStyle;
  containerClassName?: string;
  innerClassName?: string;
  onClose?: () => void;
}): JSX.Element {
  const { borderStyle, containerClassName, innerClassName } = props;
  const ref = useRef<HTMLDivElement>(null);
  const borderClasses = useBorderClasses(borderStyle);
  const doClose = props.onClose ? props.onClose : noop;
  useOutsideClick(ref, doClose, null, !props.onClose);

  return (
    <div ref={ref} className={`m-auto ${containerClassName ?? 'w-176 h-121'}`}>
      <div
        className={`relative bg-black w-full h-full text-white rounded-xl ${borderClasses} ${
          innerClassName ?? ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}
